import ApplicationController from "./application_controller";
import Dropzone from "dropzone";
import Uploader from "../modules/uploader"

export default class extends ApplicationController {
  static values = {
    url: String,
  };
  static classes = ["inactive"];
  static targets = ["dropzone", "form", "template", "button"];

  connect() {
    super.connect();

    this.completedUploads = [];

    let controller = this;
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      autoQueue: false,
      url: this.urlValue,
      clickable: true,
      uploadMultiple: true,
      acceptedFiles: 'audio/*, .mp3, m4a, .wav',
      previewTemplate: this.template,
    });
    this.bindEvents();
  }

  startUpload(event) {
    this.dropzone.getAcceptedFiles().forEach(file => {
      this.uploadFile(file);
    });
  }

  uploadFile(file) {
    let uploader = new Uploader(file, this.urlValue);
    uploader.process((error, blob) => {
      this.uploadFileCallback(error, blob);
    });
  }

  uploadFileCallback(error, blob) {
    if (error) {
      console.log(error);
    } else {
      let input = document.createElement('input')
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'bulk_process[uploaded_files_attributes[][file]');
      input.setAttribute('value', blob.signed_id);
      this.formTarget.appendChild(input);
    }
    this.completedUploads.push(blob.filename)
    this.checkSubmitForm();
  }

  checkSubmitForm() {
    if (this.dropzone.getAcceptedFiles().length == this.completedUploads.length) {
      this.formTarget.submit();
    }
  }

  bindEvents() {
    this.dropzone.on("addedfile", (file) => {
      this.activateButton(this.dropzone.files.length);
    })

    this.dropzone.on("error", (file) => {
      if (!file.accepted) this.dropzone.removeFile(file);
    });

    this.dropzone.on("removedfile", (file) => {
      if (this.dropzone.files.length === 0) {
        this.deactivateButton();
      } else {
        this.activateButton(this.dropzone.files.length);
      }
    })
  }

  activateButton(number) {
    this.buttonTarget.innerText = 'Upload ' + number + ' Files'
    this.buttonTarget.classList.remove('disabled');
  }

  deactivateButton() {
    this.buttonTarget.innerText = 'Upload Files'
    this.buttonTarget.classList.add('disabled');
  }

  get template() {
    return this.templateTarget.innerHTML;
  }

  disconnect() {
    this.dropzone.destroy();
  }
}