import {Controller} from "stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["change", "total", "comparisonTotal", "currentDate", "comparisonDate", "day", "graph", "comparison"];
  static values = {
    duration: Number,
    plot: String,
  };

  initialize() {
    //Chart.defaults.global.animation.duration = 0;
    //this.setGraphWidth();
    this.element.style.width = this.container.offsetWidth + 'px';
    this.element.style.height = '134px';
    window.setTimeout(this.renderGraph.bind(this), 1000);
  }

  setGraphWidth() {
    this.graphContainerTarget.style.width = this.wrapperTarget.offsetWidth + 'px';
  }

  renderGraph() {
    this.chart = new Chart(this.element, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [{
          label: 'Current Period',
          data: this.points,
          fill: false,
          backgroundColor: 'transparent',
          borderColor: "#D505DB",
          borderWidth: 1,
        }
        ]
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            display: false
          }
        },
        tooltips: {
          enabled: false,
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          }
        }
      }
    });
  }


  get labels() {
    if (this.labelsCache == null) {
      this.labelsCache = [];
      this.plot.forEach(point => {
        this.labelsCache.push(point[0]);
      })
    }
    return this.labelsCache;
  }

  get points() {
    if (this.pointsCache == null) {
      this.pointsCache = [];
      this.plot.forEach(point => {
        this.pointsCache.push(point[1]);
      })
    }
    return this.pointsCache;
  }

  get container() {
    return this.element.closest('.waveform');
  }

  get plot() {
    return JSON.parse(this.plotValue);
  }
}