import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static targets = ["input", "output", "range", "form", "wrap", "from", "to", "toggleButton", "resetButton"];
  static values = {
    active:{type: Boolean, default:false},
    queueTime: {type: Number, default: 2000},
    url:String
  }

  initialize() {
    this.boundCallback = this.callback.bind(this);
  }

  connect() {
    this.inputTargets.forEach(input => {
      input.addEventListener
    })
  }

  send() {
    this.formTarget.requestSubmit();
  }

  from(event) {
    this.inputTargets[0].value = event.currentTarget.value;
    this.wrapTarget.style.setProperty(`--from`, +event.currentTarget.value)
    this.updateRange();
    this.queueSend();
  }

  to(event) {
    this.inputTargets[1].value = event.currentTarget.value;
    this.wrapTarget.style.setProperty(`--to`, +event.currentTarget.value)
    this.updateRange();
    this.queueSend();
  }

  slide(e) {
    let _t = e.target;
    this.wrapTarget.style.setProperty(`--${_t.id}`, +_t.value)
    this.getDirectInput(_t.id).value = _t.value
    this.activeValue = true;
    this.updateRange();
    this.queueSend();
  }

  queueSend() {
    if (this.saveTimer) {
      window.clearTimeout(this.saveTimer);
    }
    this.saveTimer = window.setTimeout(() => {
      this.send()
    }, this.queueTimeValue);
  }

  updateRange() {
    this.setActiveStates();
    if (this.rangeIsBounds) {
      this.outputTarget.value = '{0,0}';
    }
    else {
      this.outputTarget.value = `{${this.sortedValues[0]},${this.sortedValues[1]}}`
    }
    if (this.hasRangeTarget) {
      this.rangeTarget.innerHTML = this.sortedValues[0] + ' - ' + this.sortedValues[1];
    }
  }

  toggleActive() {
    /*
    if (this.rangeIsBounds) {
      return false;
    }
     */
    this.activeValue = !this.activeValue;
    let method = this.activeValue ? 'PATCH' : 'DELETE';
    new Fetch(this.urlValue, this.boundCallback, {method: method});
  }

  callback() {
    this.setActiveStates();
  }

  resetRange() {
    this.fromTarget.value = this.rangeMin;
    this.toTarget.value = this.rangeMax;
    this.inputTargets[0].value = this.rangeMin;
    this.wrapTarget.style.setProperty(`--from`, +this.rangeMin)
    this.inputTargets[1].value = this.rangeMax;
    this.wrapTarget.style.setProperty(`--to`, +this.rangeMax)
    this.updateRange();
    this.queueSend();
  }

  setActiveStates() {
    this.element.classList.toggle('active', this.isActive());
    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.classList.toggle('active', !this.rangeIsBounds);
    }
  }

  isActive() {
    /*
    if (this.rangeIsBounds) {
      return false;
    }
     */
    return this.activeValue;
  }

  getDirectInput(origin) {
    if (origin === 'from') {
      return this.fromTarget;
    }
    return this.toTarget;
  }

  get rangeIsBounds() {
    return ((this.min === this.rangeMin) && (this.max === this.rangeMax));
  }

  get sortedValues() {
    if (this.min < this.max) {
      return [this.min, this.max];
    }
    return [this.max, this.min];
  }

  get rangeMin() {
    return parseFloat(this.inputTargets[0].getAttribute('min'));
  }

  get rangeMax() {
    return parseFloat(this.inputTargets[0].getAttribute('max'));
  }

  get min() {
    let value = parseFloat(this.inputTargets[0].value);
    if (isNaN(value) || value < this.rangeMin || value > this.rangeMax) {
      value = this.inputTargets[0].value = this.rangeMin
    }
    return value;
  }

  get max() {
    let value = parseFloat(this.inputTargets[1].value);
    if (isNaN(value) || value < this.rangeMin || value > this.rangeMax) {
      value = this.inputTargets[1].value = this.rangeMax
    }
    return value;
  }
}