/*
 Copyright 2020 MadeDaily, LLC

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"),
 to deal in the Software without restriction, including without limitation the
 rights to use, copy, modify, merge, publish, distribute, sublicense,
 and /or sell copies of the Software, and to permit persons to whom the
 Software is furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be
 included in all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
 COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF
 OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

 */

import {Controller} from "stimulus"
import Draggabilly from 'draggabilly'
import Store from "../modules/storage";

export default class extends Controller {
  static targets = ['handle', 'slider']

  initialize() {
    this.boundSetVolume = this.updateVolume.bind(this);
  }

  connect() {
    document.addEventListener("audio:volume", this.boundSetVolume);

    this.dragger = new Draggabilly(this.handleTarget, {
      axis: 'x',
      containment: true
    });
    this.dragger.on('dragMove', this.dragMove.bind(this));

    this.element.classList.toggle('muting', this.muting);
    this.initVolume();
  }

  disconnect() {
    this.dragger.destroy();
    document.removeEventListener("audio:volume", this.boundSetVolume);
  }

  initVolume() {
    this.dragger.setPosition(this.volume * 100);
    this.sendVolume();
  }

  updateVolume(event) {
    if (event.detail.element != this.element) {
      this.dragger.setPosition(event.detail.volume * 100)
    }
  }

  dragMove() {
    this.dragging = true;
    let volume = this.volumeFor(this.dragger.position.x);
    this.volume = volume
    this.muting = (volume === 0);
    this.sendVolume();
  }

  sendVolume() {
    document.dispatchEvent(new CustomEvent('audio:volume', {
      detail: {volume:this.volume, element:this.element},
    }));
  }

  setVolume(event) {
    if (!this.dragging) {
      this.dragger.setPosition(event.pageX - this.offsetLeft - 5);
      let volume = this.volumeFor(this.dragger.position.x);
      this.volume = volume;
      this.muting = (volume === 0);
      this.sendVolume();
    }
    this.dragging = false;
  }

  mute(event) {
    this.muting = !this.muting;
    if (!this.muting && this.volume == 0) {
      this.volume = 1;
    }
    this.dragger.setPosition(this.volume * 100);
    this.sendVolume();
  }

  volumeFor(position) {
    let volume = (position / 100);
    if (volume < 0) {
      return 0;
    }
    if (volume > 1) {
      return 1;
    }
    return volume;
  }

  get offsetLeft() {
    let offset = this.sliderTarget.getBoundingClientRect();
    return offset.left;
  }

  get volume() {
    if (this.muting) {
      return 0;
    }
    return Store.findOrInitialize('audio-volume', 1)
  }

  set volume(vol) {
    Store.setValue('audio-volume', vol);
  }

  get muting() {
    return parseInt(Store.findOrInitialize('audio-mute', 0)) === 1
  }

  set muting(m) {
    let value = (m) ? 1 : 0;
    Store.setValue('audio-mute', value);
    this.element.classList.toggle('muting', m);
  }


}