import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    sort: String,
    reverse: Boolean,
    url: String
  }
  static targets = ["frame", "sort", "descendingButton", "ascendingButton", "advancedSort"];

  initialize() {
    this.updateStates();
  }

  advancedSort(event) {
    this.sortValue =  event.currentTarget.value;
    this.reverseValue = this.descendingButtonTarget.classList.contains('active');
    this.descendingButtonTarget.classList.toggle('active', this.reverseValue);
    this.ascendingButtonTarget.classList.toggle('active', !this.reverseValue);
    this.updateStates();
    this.frameTarget.setAttribute('src', this.frameUrl);
  }

  advancedDirection(event) {
    this.reverseValue = event.currentTarget.dataset.direction === 'desc'
    this.descendingButtonTarget.classList.toggle('active', this.reverseValue);
    this.ascendingButtonTarget.classList.toggle('active', !this.reverseValue);
    this.frameTarget.setAttribute('src', this.frameUrl);
  }

  clearAdvancedSort() {
    if (this.hasAdvancedSortTarget) {
      this.advancedSortTarget.value = '';
      this.descendingButtonTarget.classList.toggle('active', false);
      this.ascendingButtonTarget.classList.toggle('active', false);
    }
  }

  sort(event) {
    let button = event.currentTarget;
    this.sortValue = button.dataset.sort;
    if (button.classList.contains('sorting')) {
      this.reverseValue = !this.reverseValue;
    }
    else {
      this.reverseValue = false;
    }
    this.clearAdvancedSort();
    this.updateStates();
    this.frameTarget.setAttribute('src', this.frameUrl);
  }

  updateStates() {
    this.sortTargets.forEach(sort => {
      sort.classList.toggle('sorting', sort.dataset.sort === this.sortValue)
      sort.classList.contains('sorting') && sort.classList.toggle('asc', this.reverseValue);
    })
  }

  get frameUrl() {
    return `${this.urlValue}?sort=${this.sortValue}&reverse=${this.reverseValue.toString()}`
  }
}