import {Controller} from "stimulus";
import Fetch from '../modules/fetch'
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [];
  static values = {
    parameter: String,
    column: String,
    url:String,
    handle:String,
    draggable:String
  }

  initialize() {
    this.sortable = new Sortable(this.element, {
      sort: true,
      delay: 0,
      handle: this.handle,
      draggable: this.draggable,
      onEnd: this.sorted.bind(this)
    });
  }

  sorted(event) {
    return this.updatePosition(event);
  }

  updatePosition(event) {
    let data = {};
    data[this.parameterValue] = {};
    data[this.parameterValue][this.columnValue] = event.newIndex;
    new Fetch(this.url + '/' + event.item.dataset.id, this.positionUpdated, {method:'PUT', body:data});
  }

  positionUpdated(response) {
    if (response.status !== 200) {
      alert(response.data)
    }
  }

  get url() {
    return this.urlValue;
  }

  get handle() {
    let handle = this.handleValue;
    if (handle === '') {
      handle = 'li';
    }
    return handle;
  }

  get draggable() {
    let draggable = this.draggableValue;
    if (draggable === '') {
      draggable = 'li';
    }
    return draggable;
  }

}