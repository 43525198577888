import {Controller} from "stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["point", "canvas", "tooltip", "tooltipLabel", "tooltipValue"];
  static values = {
    type: String,
    max: Number,
    total: Number
  }

  initialize() {
    if (this.hasCanvasTarget) {
      this.canvas = this.canvasTarget;
    }
    else {
      this.canvas = this.drawCanvas();
    }
    this.renderGraph();
    this.element.classList.add('processed');
    this.boundRedraw = this.redraw.bind(this);
  }

  connect() {
    document.addEventListener('miir:filtering', this.boundRedraw);
  }


  redraw() {
    this.element.classList.remove('processed');
    this.chart.destroy();
    if (!this.hasCanvasTarget) {
      this.wrapperElement.remove();
    }
    window.setTimeout(this.doRedraw.bind(this), 150);
  }

  doRedraw() {
    this.canvas = this.drawCanvas();
    this.renderGraph();
    this.element.classList.add('processed');
  }


  drawCanvas() {
    let wrapper = document.createElement("DIV");
    wrapper.classList.add('wrapper');
    wrapper.style.width = this.wrapperSize;
    wrapper.style.height = this.wrapperSize;
    let canvas = document.createElement("CANVAS");
    canvas.style.width = '100%';
    canvas.style.height = this.wrapperSize;
    wrapper.appendChild(canvas);
    this.element.appendChild(wrapper);
    return canvas;
  }

  renderGraph() {
    this.chart = new Chart(this.canvas, {
      type: this.typeValue,
      data: {
        labels: this.labels,
        datasets: [{
          data: this.points,
          backgroundColor: this.colors
        }]
      },
      options: {
        cutout: '80%',
        borderWidth: 0,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: this.tooltip.bind(this)
          },
        },
      }
    });
  }

  tooltip(context) {
    let tooltip = context.tooltip;
    console.log(tooltip);
    if (tooltip.opacity === 0) {
      this.tooltipLabelTarget.innerHTML = this.tooltipTarget.dataset.label;
      this.tooltipValueTarget.innerHTML = this.tooltipTarget.dataset.value;
      this.tooltipTarget.removeAttribute('style');
      return;
    }
    this.tooltipLabelTarget.innerHTML = tooltip.dataPoints[0].label;
    this.tooltipValueTarget.innerHTML = this.percentOfTotal(tooltip.dataPoints[0]);
    this.tooltipTarget.style.color = this.tooltipColor(tooltip);
  }

  tooltipColor(tooltip) {
    return tooltip.labelColors[0].backgroundColor;
  }

  percentOfTotal(value) {
    return Math.round((value.parsed / this.totalValue) * 100) + '%'
  }

  get wrapperSize() {
    if (this.maxValue) {
      if (this.element.offsetWidth > this.maxValue) {
        return this.maxValue + 'px';
      }
    }
    return (this.element.offsetWidth  * 0.9)+ 'px';
  }

  get points() {
    let points = [];
    this.pointTargets.forEach((point, index) => {
      points.push(point.dataset.count);
    });
    return points;
  }

  get labels() {
    let labels = [];
    this.pointTargets.forEach((point, index) => {
      labels.push(point.dataset.label);
    });
    return labels;
  }

  get colors() {
    return ["#D505DB", "#00C6FF", "#2299C1", "#3B7892", "#545663", "#746F78"];
  }

  get wrapperElement() {
    return this.element.querySelector('.wrapper');
  }
}