import PieGraphController from "./pie_graph_controller"
import Chart from 'chart.js/auto';

export default class extends PieGraphController {

  renderGraph() {
    this.chart = new Chart(this.canvas, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.points,
          backgroundColor: 'transparent',
          borderColor: this.colors,
          borderWidth: '3'
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: this.tooltip.bind(this)
          },
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            display: false,
          }
        }
      }
    });
  }

  percentOfTotal(value) {
    return value.raw;
  }

  tooltipColor(tooltip) {
    return tooltip.labelColors[0].borderColor;
  }

  get wrapperSize() {
    if (this.maxValue) {
      if (this.element.offsetWidth > this.maxValue) {
        return this.maxValue + 'px';
      }
    }
    return this.element.offsetWidth + 'px';
  }


  get colors() {
    return ["#D505DB", "#00C6FF", "#2299C1", "#3B7892", "#545663", "#746F78"];
  }
}