/*
 Copyright 2020 MadeDaily, LLC

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"),
 to deal in the Software without restriction, including without limitation the
 rights to use, copy, modify, merge, publish, distribute, sublicense,
 and /or sell copies of the Software, and to permit persons to whom the
 Software is furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be
 included in all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
 COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF
 OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

 */
import {Controller} from "stimulus"
import Fetch from "../modules/fetch";

//TODO - REMOVE THE AUDIO PLAYER STUFF FROM HERE AND CUT IT DOWN TO JUST ACTIVATE AND NEXT/PREVIOUS STUFF
export default class extends Controller {
  static values = {
    duration: Number,
    retry: String,
    name: String,
    artist: String,
    media: String
  };
  static targets = ["audio", "waveform", "scrubber", "time", "chill"];

  initialize() {
    this.boundDeactivate = this.deactivate.bind(this);
    //this.boundProgressAnimation = this.progressAnimation.bind(this);
    //this.boundUpdateTime = this.updateTime.bind(this);
    //this.boundPlay = this.play.bind(this);
    //this.boundScrub = this.scrub.bind(this);
  }

  connect() {
    this.active = false;
    this.playing = false;
    this.currentSecond = 0;
    document.addEventListener("turbo:before-cache", this.boundDeactivate);
    document.addEventListener("audio:activating", this.boundDeactivate);
    /*
    this.element.addEventListener('audio:play', this.boundPlay);
    this.element.addEventListener('audio:scrub', this.boundScrub);
     */
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.boundDeactivate);
    document.removeEventListener("audio:activating", this.boundDeactivate);
    /*
    this.element.removeEventListener('audio:play', this.boundPlay);
    this.element.removeEventListener('audio:pause', this.boundScrub);
     */
  }

  /*
  setVolume(event) {
    this.volume = event.detail.volume;
    this.audioTarget.volume = this.volume;
  }
   */

  toggle(event) {
    if (this.playing) {
      document.dispatchEvent(new CustomEvent('audio:pause', {
        detail: {element: this.element.id}
      }));
    } else {
      document.dispatchEvent(new CustomEvent('audio:play', {
        detail: {element: this.element.id}
      }));
    }
  }

  activate(event) {
    if (!this.active) {
      this.element.classList.add('open');
      this.active = true;
    }
  }

  target() {
    this.element.scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
  }

  deactivate(event) {
    this.pause();
    this.element.classList.remove('open');
    this.active = false;
  }

  play() {
    this.element.classList.add('playing')
    this.playing = true;
  }

  pause() {
    this.element.classList.remove('playing')
    this.playing = false;
  }

  /*
  retryAudio(response) {
    if (response.status === 200) {
      let volume = this.audioTarget.volume;
      let time = this.audioTarget.currentTime;
      this.audioTarget.outerHTML = response.data;
      this.audioTarget.volume = volume;
      this.audioTarget.currentTime = time;
      this.startPlayback();
    } else {
      console.log(response.status);
    }
  }

  restart() {
    this.audioTarget.currentTime = 0;
  }

  setMode(mode) {
    this.mode = this.modes.indexOf(mode) || 0;
  }

  setPrimaryChill() {
    this.setMode('primary')
    this.audioTarget.currentTime = this.primaryChillPhrase.startValue;
  }

  setChills() {
    this.setMode('chills')
    this.nextPhrase();
  }
   */

  nextPhrase(time) {
    let chills = this.chillPhrases.slice().reverse();
    let next = null;
    chills.forEach(chill => {
      if (chill.startTime > time) {
        next = chill;
      }
    })
    if (next) {
      next.play();
    }
    else {
      this.nextTrackPhrase();
    }
  }

  nextTrackPhrase() {
    let song = this.nextTrack;
    if (song) {
      song.nextPhrase(0);
    } else {
      document.dispatchEvent(new CustomEvent('audio:pause', {
        detail: {element: this.element.id}
      }));
    }
  }

  previousPhrase(time) {
    let chills = this.chillPhrases.slice();
    let previous = null;
    chills.forEach(chill => {
      if ((chill.startTime + 2) < time) {
        previous = chill;
      }
    })
    if (previous) {
      previous.play();
    } else {
      this.previousTrackPhrase();
    }
  }

  previousTrackPhrase() {
    let song = this.previousTrack;
    if (song) {
      song.previousPhrase(song.durationValue);
    } else {
      document.dispatchEvent(new CustomEvent('audio:pause', {
        detail: {element: this.element.id}
      }));
    }
  }


  /*
  startPlayback() {
    let promise = this.audioTarget.play();
    if (promise !== undefined) {
      promise.then(() => {
        this.retrying = false;
      }).catch(error => {
        if (!this.retrying) {
          this.retrying = true;
          new Fetch(this.retryValue, this.retryAudio.bind(this), {method: 'PUT'});
        } else {
          console.log('Could not play track after retry');
        }
      });
    }
  }
   */



  /*
  scrub(event) {
    this.playing = false;
    this.audioTarget.pause();
  }
   */
  get nextTrack() {
    return this.application.getControllerForElementAndIdentifier(this.element.nextElementSibling, 'song');
  }

  get previousTrack() {
    return this.application.getControllerForElementAndIdentifier(this.element.previousElementSibling, 'song');
  }

  get songName() {
    return this.nameValue;
  }

  get artistName() {
    return this.artistValue;
  }

  get media() {
    return this.mediaValue;
  }

  get mediaRetry() {
    return this.retryValue;
  }

  get songDuration() {
    return this.durationValue;
  }

  set scrubberTime(time) {
    let position = time * this.pixelsPerSecond;
    this.scrubberTarget.style.left = position + "px";
  }

  /*
  updateTime() {
    if (this.playing) {
      if (this.audioTarget.currentTime >= this.durationValue) {
        document.dispatchEvent(new CustomEvent('miir:audio:playback-ended', {
          detail: this.element.id
        }));
        this.pause();
      }
      let time = new Date(1000 * this.audioTarget.currentTime).toISOString().substr(this.timeFrom, this.timeLength)
      this.timeTarget.innerText = time;
      window.setTimeout(this.boundUpdateTime, 1000);
    }
  }

   */

  /*
  progressAnimation() {
    let position = this.audioTarget.currentTime * this.pixelsPerSecond;
    this.scrubberTarget.style.left = position + "px";

    if (this.playing) {
      window.requestAnimationFrame(this.boundProgressAnimation);
    }
    if (this.mode > 0) {
      this.checkChillStatus();
    }
  };
   */



  get pixelsPerSecond() {
    return this.waveformTarget.offsetWidth / this.durationValue;
  }

  /*
  get timeFrom() {
    if (this.durationValue < 600) {
      return 15;
    }
    if (this.durationValue < 3600) {
      return 14;
    }
    if (this.durationVAlue < 36000) {
      return 12;
    } else {
      return 11;
    }

  }

  get timeLength() {
    if (this.durationValue < 600) {
      return 4;
    }
    if (this.durationValue < 3600) {
      return 5;
    }
    if (this.durationValue < 36000) {
      return 7;
    } else {
      return 8;
    }
  }
   */

  get chillPhraseStarts() {
    let starts = [];
    this.chillPhrases.forEach(chill => {
      starts.push(chill.startTime);
    });
    return starts;
  }

  get chillPhrases() {
    if (!this.chillPhraseCache) {
      this.chillPhraseCache = [];
      let chills = [];
      this.chillTargets.forEach(target => {
        let chill = this.application.getControllerForElementAndIdentifier(target, 'chill-phrase');
        chills.push(chill);
      });
      this.chillPhraseCache = chills.sort(function(a,b) {return a.startTime - b.startTime})
    }
    return this.chillPhraseCache;
  }

  get primaryChillPhrase() {
    if (this.primary == null) {
      this.chillPhrases.forEach(phrase => {
        if (phrase.isPrimary()) {
          this.primary = phrase
        }
      })
    }
    return this.primary;
  }

  get lastChillPhrase() {
    return this.chillPhrases[this.chillPhrases.length - 1]
  }

  chillAtTime(time) {
    if (this.currentChillIndex != null && this.chillPhrases[this.currentChillIndex].timeIsInPhrase(time)) {
      return this.chillPhrases[this.currentChillIndex];
    }
    this.currentChillIndex = null;
    this.chillPhrases.forEach((chill, index) => {
      if (chill.timeIsInPhrase(time)) {
        this.currentChillIndex = index;
      }
    });
    return (this.currentChillIndex == null) ? null : this.chillPhrases[this.currentChillIndex];
  }
}