import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static values = {
    url: String,
    filtering: Boolean
  }

  initialize() {
    this.filtering = this.filteringValue;
  }

  send() {
    this.filtering = !this.filtering;
    new Fetch(this.urlValue, function(response) { Turbo.renderStreamMessage(response.data)}, {method: 'PATCH', body:{filtering:this.filteringInteger}});
  }

  get filteringInteger() {
    return this.filtering ? '1' : '0';
  }
}