import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["option", "typeahead"];

  initialize() {
  }

  connect() {
    if (this.hasTypeaheadTarget) {
      this.observe();
    }
  }

  observe() {
    let observer = new MutationObserver(mutationRecords => {
      this.send();
    });
    observer.observe(this.typeaheadTarget, { childList: true });
  }

  send() {
    this.element.requestSubmit();
  }
}