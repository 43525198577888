import {DirectUpload} from "@rails/activestorage";
export default class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(file, url, this);
  }

  process(callback) {
    this.upload.create(callback);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    let progress = (event.loaded / event.total) * 100;
    document.dispatchEvent(new CustomEvent('dz:upload-progress', {
      detail: {file:this.upload.file.name, progress:progress}
    }));
  }
}