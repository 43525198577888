import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static targets = ['button']
  static values = {
    url: String,
    active: Boolean
  }

  initialize() {
    this.boundCallback = this.callback.bind(this);
  }

  toggle() {
    this.activeValue = !this.activeValue;
    new Fetch(this.urlValue, this.boundCallback, {method: this.method});
  }

  callback() {
    this.button.classList.toggle('active', this.activeValue)
  }

  get method() {
    return this.activeValue ? 'DELETE' : 'PATCH'
  }

  get button() {
    if (this.hasButtonTarget) {
      return this.buttonTarget;
    }
    return this.element;
  }
}