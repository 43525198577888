import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static values = {
    url: String,
    id: Number
  }

  initialize() {
    this.isFavorite = (this.userFavorites.indexOf(this.idValue) > -1);
    this.boundUpdate = this.update.bind(this);
    this.toggleClass();
  }

  connect() {
    document.addEventListener("miir:favorite", this.boundUpdate);
  }

  disconnect() {
    document.removeEventListener("miir:favorite", this.boundUpdate);
  }

  setTrack(trackId) {
    this.idValue = parseInt(trackId.split('_')[1]);
    this.isFavorite = (this.userFavorites.indexOf(this.idValue) > -1);
    this.toggleClass();
  }

  update(event) {
    if (event.detail.id === this.idValue) {
      this.isFavorite = event.detail.favorite;
      this.toggleClass();
    }
  }

  toggleClass() {
    this.element.classList.toggle('favorite', this.isFavorite);
  }

  toggle() {
    new Fetch(this.toggleUrl, () => { document.dispatchEvent(new CustomEvent('miir:favorite', { detail: {id: this.idValue, favorite:!this.isFavorite} }))}, {method: this.toggleMethod, body: {track_id: this.idValue}});
  }

  get toggleMethod() {
    return this.isFavorite ? 'DELETE' : 'POST'
  }

  get toggleUrl() {
    return this.urlValue + (this.isFavorite ? '/' + this.idValue : '');
  }

  get userFavorites() {
    let favorites = document.head.querySelector("[name=favorite-tracks]");
    if (favorites == null) {
      return [];
    }
    return JSON.parse(favorites.content);
  }
}