/*
 Copyright 2020 MadeDaily, LLC

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"),
 to deal in the Software without restriction, including without limitation the
 rights to use, copy, modify, merge, publish, distribute, sublicense,
 and /or sell copies of the Software, and to permit persons to whom the
 Software is furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be
 included in all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
 COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF
 OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

 */
import {Controller} from "stimulus"
import Fetch from "../modules/fetch";
import Store from "../modules/storage";

export default class extends Controller {
  static values = {
    duration: Number,
    retry: String,
  };
  static targets = ["audio", "media", "waveform", "scrubber", "time", "chill", "name", "artist", "duration", "mode", "chillMode", "bookmark"];

  initialize() {
    this.boundDeactivate = this.deactivate.bind(this);
    this.boundActivate = this.activate.bind(this);
    this.boundPause = this.pause.bind(this);
    this.boundProgressAnimation = this.progressAnimation.bind(this);
    this.boundUpdateTime = this.updateTime.bind(this);
    this.boundScrub = this.scrub.bind(this);
  }

  connect() {
    this.currentSong = null;
    this.playing = false;
    this.currentSecond = 0;
    this.loadMode();
    document.addEventListener("turbo:before-cache", this.boundDeactivate);
    document.addEventListener("audio:play", this.boundActivate);
    document.addEventListener("audio:pause", this.boundPause);
    document.addEventListener('audio:scrub', this.boundScrub);
  }

  disconnect() {
    this.deactivate();
    document.removeEventListener("turbo:before-cache", this.boundDeactivate);
    document.removeEventListener("audio:play", this.boundActivate);
    document.removeEventListener("audio:pause", this.boundPause);
    document.removeEventListener('audio:scrub', this.boundScrub);
  }

  loadMode() {
    this.mode = parseInt(Store.findOrInitialize('play-all-mode', 0))
    this.chillMode = parseInt(Store.findOrInitialize('chill-length', 0))
  }

  setVolume(event) {
    this.volume = event.detail.volume;
    this.audioTarget.volume = this.volume;
  }

  activate(event) {
    if (this.currentSongId != event.detail.element) {
      this.stopCurrentSong();
      this.element.classList.add('open');
      document.body.classList.add('audio-playing');
      this.currentSong = this.application.getControllerForElementAndIdentifier(document.getElementById(event.detail.element), 'song');
      this.currentSong.activate();
      this.setSongData();
    }
    this.play();
  }

  deactivate(event) {
    this.element.classList.remove('open');
    document.body.classList.remove('audio-playing');
    this.active = false;
    if (this.playing) {
      this.pause();
    }
  }

  stopCurrentSong() {
    this.currentSong && this.currentSong.deactivate();
  }

  setSongData() {
    this.nameTarget.innerText = this.currentSong.songName;
    this.artistTarget.innerText = this.currentSong.artistName;
    this.durationTarget.innerText = this.formattedTime(this.songDuration);
    this.waveformTarget.innerHTML = this.currentSong.waveformTarget.innerHTML;
    this.mediaTarget.setAttribute('src', this.currentSong.media);
    this.audioTarget.load();
    this.bookmark.setTrack(this.currentSongId);
  }

  retryAudio(response) {
    if (response.status === 200) {
      let volume = this.audioTarget.volume;
      let time = this.audioTarget.currentTime;
      this.audioTarget.outerHTML = response.data;
      this.audioTarget.volume  = volume;
      this.audioTarget.currentTime = time;
      this.startPlayback();
    }
    else {
      console.log(response.status);
    }
  }

  restart() {
    this.audioTarget.currentTime = 0;
  }

  cycleMode(event) {
    this.mode = this.modes[this.mode + 1] ? this.mode + 1 : 0;
    Store.setValue('play-all-mode', this.mode);
  }

  cycleChillMode(event) {
    this.chillMode = this.chillModes[this.chillMode + 1] ? this.chillMode + 1 : 0;
    Store.setValue('chill-length', this.chillMode);
    document.dispatchEvent(new CustomEvent('chill:reset'));
    this.currentChill = null;
  }

  /*
  setPrimaryChill() {
    this.setMode('primary')
    this.audioTarget.currentTime = this.primaryChillPhrase.startValue;
  }
   */

  next(event) {
    if (this.mode < 2) {
      this.skipTrack();
    }
    else {
      this.currentSong.nextPhrase(this.currentTime);
    }
  }

  previous(event) {
    if (this.primaryChillPlayback) {
      let song = this.currentSong.previousTrack;
      if (song) {
        song.primaryChillPhrase.play();
      }
    }
    else {
      if (this.mode < 2) {
        this.previousTrack();
      }
      else {
        this.currentSong.previousPhrase(this.currentTime);
      }
    }
  }

  toggle(event) {
    if (this.playing) {
      this.pause(event);
    }
    else {
      this.play(event);
    }
  }

  play(event) {
    if (this.audioTarget.currentTime >= this.currentSong.songDuration) {
      this.audioTarget.currentTime = 0;
    }
    if (event) {
      if (event.detail && event.detail.playAtTime) {
        this.audioTarget.currentTime = event.detail.playAtTime;
      }
      //document.dispatchEvent(new CustomEvent('miir:audio:playback-interrupted'));
      //this.setMode('song');
    }
    this.currentChill = null;
    this.playing = true;
    this.element.classList.add('playing')
    this.currentSong.play();
    this.startPlayback();
    this.updateTime();
    window.requestAnimationFrame(this.boundProgressAnimation);
    this.sendAnalytics();
  }

  seek(event) {
    if (event.detail.element) {
      this.activate(event);
    }
    this.currentChill = null;
    this.audioTarget.currentTime = event.detail.time;
    this.play();
  }

  startPlayback() {
    let promise = this.audioTarget.play();
    if (promise !== undefined) {
      promise.then(() => {
        this.retrying = false;
      }).catch(error => {
        if (!this.retrying) {
          this.retrying = true;
          new Fetch(this.currentSong.mediaRetry, this.retryAudio.bind(this), {method:'PUT'});
        } else {
          console.log('Could not play track after retry');
        }
      });
    }
  }

  pause(event) {
    this.playing = false;
    this.element.classList.remove('playing')
    this.audioTarget.pause()
    this.currentSong.pause();
  }

  scrub(event) {
    this.pause();
  }

  formattedTime(value) {
    return new Date(1000 * value).toISOString().substr(this.timeFrom, this.timeLength)
  }

  skipTrack() {
    let nextTrack = this.currentSong.nextTrack;
    if (nextTrack) {
      nextTrack.toggle();
    }
  }

  previousTrack() {
    let track = this.currentSong.previousTrack;
    if (track) {
      track.toggle();
    }
  }

  updateTime() {
    if (this.playing) {
      if (this.audioTarget.currentTime >= this.songDuration) {
        if (this.mode < 1) {
          document.dispatchEvent(new CustomEvent('miir:audio:playback-ended', {
            detail: this.element.id
          }));
          this.pause();
        }
        else {
          this.skipTrack();
        }
      }
      this.timeTarget.innerText = this.formattedTime(this.audioTarget.currentTime);
      window.setTimeout(this.boundUpdateTime, 1000);
    }
  }

  progressAnimation() {
    let position = this.audioTarget.currentTime * this.pixelsPerSecond;
    this.scrubberTarget.style.left = position + "px";
    this.currentSong.scrubberTime = this.audioTarget.currentTime;

    if (this.playing) {
      window.requestAnimationFrame(this.boundProgressAnimation);
    }
    if (this.mode > 1) {
      this.checkChillStatus();
    }
  };

  async checkChillStatus() {
    let chill = this.currentChill;
    if (this.primaryChillPlayback) {
      if (chill !== this.primaryChillPhrase) {
        if (this.currentTime < this.primaryChillPhrase.startTime) {
          this.primaryChillPhrase.play();
        } else {
          let nextTrack = this.currentSong.nextTrack;
          if (nextTrack) {
            nextTrack.primaryChillPhrase.play();
          } else {
            document.dispatchEvent(new CustomEvent('miir:audio:playback-ended', {
              detail: this.element.id
            }));
            return this.pause();
          }
        }
      }
    }
    else {
      if (chill == null) {
        this.next();
      }
    }
  }

  sendAnalytics() {
    try {
      gtag('event', 'start_playback', {
        'event_category': 'engagement',
        'event_label': this.currentSong.songName,
        'value': this.audioTarget.currentTime
      });
    }
    catch(ex) {
      console.log('error sending analytics');
      console.log(ex.message)
    }
  }

  get bookmark() {
    this.bookmarker = this.application.getControllerForElementAndIdentifier(this.bookmarkTarget, 'favorite');
    return this.bookmarker;
  }

  get currentTime() {
    return this.audioTarget.currentTime;
  }

  get currentSongId() {
    if (this.currentSong == null) {
      return null;
    }
    return this.currentSong.element.id;
  }

  get pixelsPerSecond() {
    return this.waveformTarget.offsetWidth / this.songDuration;
  }
  
  get songDuration() {
    return this.currentSong ? this.currentSong.songDuration : 0
  }

  get timeFrom() {
    if (this.songDuration < 600) {
      return 15;
    }
    if (this.songDuration < 3600) {
      return 14;
    }
    if (this.songDuration < 36000) {
      return 12;
    }
    else {
      return 11;
    }

  }

  get timeLength() {
    if (this.songDuration < 600) {
      return 4;
    }
    if (this.songDuration < 3600) {
      return 5;
    }
    if (this.songDuration < 36000) {
      return 7;
    }
    else {
      return 8;
    }
  }

  get primaryChillPlayback() {
    return this.mode === 3;
  }

  get primaryChillPhrase() {
    return this.currentSong.primaryChillPhrase;
  }

  get lastChillPhrase() {
    return this.currentSong.lastChillPhrase;
  }

  get currentChill() {
    let time = this.audioTarget.currentTime;
    if (this.currentChillCache == null || this.currentChillCache.endTime < time) {
      this.currentChillCache = this.currentSong.chillAtTime(time);
    }
    return this.currentChillCache
  }

  set currentChill(value) {
    this.currentChillCache = value;
  }

  get modes() {
    return [{css:'off', value:'Off'}, {css:'on', value:'Full Songs'}, {css: 'on', value: 'All Chills'}, {css:'on', value:'Primary Chills'}]
  }

  get chillModes() {
    return ['normal', 'long', 'abr', 'off']
  }

  get mode() {
    return this.modeValue;
  }

  set mode(value) {
    this.modeValue = value;
    let values = this.modes[value];
    this.modeTarget.setAttribute('class', values.css);
    this.modeTarget.lastElementChild.innerText = values.value;
  }

  get chillMode() {
    return this.chillModeValue;
  }

  set chillMode(value) {
    this.chillModeValue = value;
    this.chillModeTarget.lastElementChild.innerText = this.chillModes[value];
    this.chillModeTarget.setAttribute('class', this.chillModes[value]);
  }
}