import {Controller} from "stimulus"
import Fetch from "../modules/fetch";

export default class extends Controller {
  static values = {
    play: String,
    page: Number
  };
  static targets = ["song"];

  initialize() {
    this.playing = false;
    this.boundAdvance = this.advance.bind(this);
    this.boundCancel = this.cancel.bind(this);
  }

  disconnect() {
    if (this.playing) {
      this.pause();
    }
  }

  bindEvents() {
    document.addEventListener("miir:audio:playback-ended", this.boundAdvance);
    document.addEventListener("miir:audio:playback-interrupted", this.boundCancel);
  }

  unbindEvents() {
    document.removeEventListener("miir:audio:playback-ended", this.boundAdvance);
    document.removeEventListener("miir:audio:playback-interrupted", this.boundCancel);
  }

  toggle(event) {
    if (this.playing) {
      this.pause();
    }
    else {
      this.play();
    }
  }

  setCurrentSong(event) {
    this.songTargets.forEach((element, index) => {
      if (element.id === event.detail) {
        this.currentSongIndex = index;
        this.currentSong.setMode(this.playValue);
      }
    });
  }

  setMode(event) {
    if (this.playValue != event.currentTarget.value) {
      this.playValue = event.currentTarget.value;
      if (this.currentSong) {
        this.currentSong.setMode(this.playValue);
      }
    }
  }

  play(event) {
    this.element.classList.toggle('playing-all', true)
    this.playing = true
    this.currentSongIndex = -1;
    this.bindEvents();
    this.playNextSong();
  }

  pause(event) {
    let song = this.currentSong;
    song.pause();
    this.cancel();
  }

  cancel(event) {
    this.unbindEvents();
    this.playing = false;
    this.element.classList.toggle('playing-all', false)
  }

  advance(event) {
    if (this.eventIsCurrent(event)) {
      this.playNextSong();
    }
  }

  playNextSong() {
    let song = this.getNextSong();
    if (song != null) {
      song.activate();
      this.setStartTime(song);
      song.play();
    }
  }

  setStartTime(song) {
    if (this.playValue === 'primary') {
      song.setPrimaryChill();
    }
    if (this.playValue === 'chills') {
      song.setChills();
    }
    if (this.playValue === 'song') {
      song.restart();
    }

  }

  eventIsCurrent(event) {
    try {
      return event.detail === this.songTargets[this.currentSongIndex].id
    }
    catch (ex) {
      return false;
    }
  }

  getNextSong() {
    this.currentSongIndex = this.currentSongIndex + 1;
    if (this.currentSongIndex >= this.totalSongs) {
      this.currentSongIndex = 0;
        this.pause();
        return null;
    } else {
      return this.currentSong;
    }
  }

  get totalSongs() {
    return this.songTargets.length;
  }

  get currentSong() {
    return this.application.getControllerForElementAndIdentifier(this.songTargets[this.currentSongIndex], 'audio-player');
  }
}