import {Controller} from "stimulus"
import Store from "../modules/storage";
export default class extends Controller {
  static values = {
    duration: Number,
    shortStart: Number,
    shortEnd: Number,
    normalStart: Number,
    normalEnd: Number,
    longStart: Number,
    longEnd: Number,
    primary: Boolean
  };

  initialize() {
    this.boundPosition = this.position.bind(this);
    this.boundReset = this.reset.bind(this);
    this.mode = Store.findOrInitialize('chill-length', '0');
  }

  connect() {
    this.position();
    document.addEventListener('miir:filtering', this.boundPosition);
    document.addEventListener('chill:reset', this.boundReset);
  }

  disconnect() {
    document.removeEventListener('miir:filtering', this.boundPosition);
    document.removeEventListener('chill:reset', this.boundReset);
  }

  play() {
    document.dispatchEvent(new CustomEvent('audio:seek', {
      detail: {time: this.startTime, element:this.song}
    }));
  }

  isVisible() {
    if (this.mode == '3') {
      return false;
    }
    return this.endTime > 0
  }

  position() {
    if (this.isVisible()) {
      this.element.style.width = this.chillPhraseWidth;
      this.element.style.left = this.chillPhraseOffset;
      this.element.style.opacity = 1;
      this.element.classList.remove('loading')
      this.element.style.display = 'block';
    }
    else {
      this.element.style.display = 'none';
    }
  }

  reset() {
    this.element.classList.add('loading')
    this.mode = Store.getValue('chill-length');
    this.cachedStartValue = null;
    this.cachedEndValue = null;
    this.position();
  }

  isPrimary() {
    return this.primaryValue;
  }

  timeIsInPhrase(time) {
    return (time >= this.startTime && time <= this.endTime);
  }

  get chillPhraseWidth() {
    return ((this.endTime - this.startTime)  * this.pixelsPerSecond) + 'px';
  }

  get chillPhraseOffset() {
    return (this.startTime * this.pixelsPerSecond) + 'px';
  }

  get pixelsPerSecond() {
    return this.waveform.offsetWidth / this.durationValue;
  }

  get waveform() {
    return this.element.closest('.waveform');
  }

  get song() {
    let el = this.element.closest('.song');
    if (el) {
      return el.id;
    }
    return null;
  }

  get timeRange() {
    return [this.startTime, this.endTime]
  }

  get startTime() {
    if (this.cachedStartValue == null) {
      switch (this.mode) {
        case '0':
          this.cachedStartValue = this.normalStartValue;
          break;
        case '1':
          this.cachedStartValue = this.longStartValue;
          break;
        case '2':
          this.cachedStartValue = this.shortStartValue;
          break;
        default:
          this.cachedStartValue = this.normalStartValue;
      }
    }
    return this.cachedStartValue;
  }

  get endTime() {
    if (this.cachedEndValue == null) {
      switch (this.mode) {
        case '0':
          this.cachedEndValue = this.normalEndValue;
          break;
        case '1':
          this.cachedEndValue = this.longEndValue;
          break;
        case '2':
          this.cachedEndValue = this.shortEndValue;
          break;
        default:
          this.cachedEndValue = this.normalEndValue;
      }
    }
    return this.cachedEndValue;
  }

}