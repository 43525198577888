import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["container", "input", "option", "filters"];
  static values = {
    url: String,
    parameter: String
  }

  initialize() {
    this.string = '';
    this.valueCache = {"":[]};
  }
  
  connect() {
    this.cacheActiveValues();
  }

  show(event) {
    this.string = event.currentTarget.value.toLowerCase();
    this.showOptions();
  }

  add(event) {
    this.hide();
    let value = event.currentTarget.innerText;
    if (!this.activeValues.includes(value)) {
      this.filtersTarget.appendChild(this.getInput(value));
      this.cacheActiveValues();
    }
  }

  getInput(value) {
    let label = document.createElement('label')
    label.classList.add('filter-option')
    label.innerHTML = `<input type="checkbox" name="${this.parameterValue}" value="${value}" data-typeahead-target="option" data-action="filter#send" checked> ${value}`
    return label
  }

  remove(event) {
    if (confirm('Would you like to remove the filter: ' + event.currentTarget.value + '?')) {
      event.currentTarget.remove();
      this.cacheActiveValues();
    }
  }

  hide() {
    if (this.dialogIsVisible) {
      this.containerTarget.style.display = 'none';
      this.element.closest('.filter-options').classList.remove('typeahead');
      this.string = '';
      this.inputTarget.value = '';
    }
  }

  showOptionsDialog() {
    if (!this.dialogIsVisible) {
      this.containerTarget.style.display = 'block';
      this.element.closest('.filter-options').classList.add('typeahead');
      this.startCloseEventListener();
    }
  }

  startCloseEventListener() {
    console.log('starting close listener');
    const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    const outsideClickListener = event => {
      if (!this.containerTarget.contains(event.target) && isVisible(this.containerTarget)) { // or use: event.target.closest(selector) === null
        console.log('outside click');
        this.hide();
        removeClickListener()
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener)
    };

    document.addEventListener('click', outsideClickListener)
  }
  
  cacheActiveValues() {
    this.activeValues = [];
    this.optionTargets.forEach(option => {
      this.activeValues.push(option.value);
    });
  }

  async showOptions() {
    let values = await this.values;
    this.containerTarget.innerHTML = ''
    values.forEach(value => {
      let el = document.createElement('li')
      el.setAttribute('data-action', 'click->typeahead#add');
      el.innerText = value;
      this.containerTarget.appendChild(el);
    })
    this.showOptionsDialog();
  }

  get values() {
    if (this.valueCache[this.firstChar]) {
      return new Promise(resolve => {
        resolve(this.matchingValues);
      });
    }
    return new Promise(resolve => {
      fetch(this.urlValue + '?q=' + this.firstChar)
        .then(response => response.json())
        .then(data => {this.valueCache[this.firstChar] = data; resolve(this.matchingValues)});
    });
  }

  get matchingValues() {
    let match = [];
    this.valueCache[this.firstChar].forEach(value => {
      if (value.toLowerCase().startsWith(this.string)) {
        match.push(value);
      }
    })
    return match;
  }

  get firstChar() {
    return this.string.charAt(0);
  }

  get dialogIsVisible() {
    if (this.containerTarget.style.display === 'block') {
      return true;
    }
    return false;
  }

}