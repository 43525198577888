/*
 Copyright 2020 MadeDaily, LLC

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"),
 to deal in the Software without restriction, including without limitation the
 rights to use, copy, modify, merge, publish, distribute, sublicense,
 and /or sell copies of the Software, and to permit persons to whom the
 Software is furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be
 included in all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
 COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF
 OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

 */
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["form", "input"];

  setValue(event) {
    let value = this.getValue(event.currentTarget);
    this.inputTarget.value = value;
    //Rails.fire(this.formTarget, 'submit');
    this.formTarget.requestSubmit();
  }

  getValue(element) {
    let value = element.value || element.dataset.value;
    if (element.type && element.type === 'checkbox') {
      if (!element.checked) {
        return '0';
      }
    }
    return value;
  }
}